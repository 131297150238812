import React from "react";


import INFO from "../data/user";

import "./styles/homepage.css";

const Homepage = () => {
	return (
		<React.Fragment>
			<div className="page-content">
				<div className="content-wrapper">

					<div className="homepage-container">
						<div className="homepage-first-area">
							<div className="homepage-first-area-left-side">
								<div className="title homepage-title">
									{INFO.homepage.title}
								</div>

								<div className="subtitle homepage-subtitle">
									{INFO.homepage.description}
								</div>
							</div>

							<div className="homepage-first-area-right-side">
								<div className="homepage-image-container">
									<div className="homepage-image-wrapper">
										<img
											src="homepage.jpg"
											alt="about"
											className="homepage-image"
										/>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;
